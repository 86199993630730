import { useSSR, useTranslation } from "react-i18next";
import { EnrollmentResultContent, PlanSummaryRow } from "../../styles/Enrollment.styled";
import { Button } from "../../styles/Button.styled";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { shareAsaleNew, shareAsaleNew2 } from "../../../utils/shareAsaleNew";
import { useEffect, useState } from "react";
import isAdmin from "../../../utils/isAdmin";
import { getEnrollmentRecord } from "../../../api/enrollment";
import { updateDealHubspot } from "../../../api/api";
import { dealStages } from "../../../config";
import { adminPortalAgent } from "../../../config";
import jsPDF from "jspdf";
import { b64Satisfy } from "../../../utils/b64Satisfy";
import { VerticalAlignBottomOutlined } from "@ant-design/icons";

export const Successful = ({ status, selectedPlans, enrollmentTokens, getScriptForAgent }: {
    status: string
    selectedPlans: any
    enrollmentTokens: any
    getScriptForAgent?: any
}) => {
    const { t,i18n } = useTranslation('enrollment');
    const [isAgent,setIsAgent] = useState(false);
    const [state,setState] = useState('')
    const [isMi,setIsMi] = useState(sessionStorage.getItem("state")=="MI")
    
    let allDocs: any = {};
    selectedPlans.forEach((plan: any) => {
        const { commodity_name, docs } = plan;
        if (!allDocs[commodity_name]) allDocs[commodity_name] = [];
        const availableDocs = docs.filter((val: any) => val.display_in_thank_you_page === true);
        allDocs[commodity_name] = availableDocs;
    })

     const generatePDF = async () => {
            const id = enrollmentTokens[0]?.enrollment_id;
            const doc = new jsPDF();
            //@ts-ignore
            const miUser = JSON.parse(sessionStorage.getItem("miUser"));
            doc.addFileToVFS("Satisfy-Regular.ttf", b64Satisfy);
            doc.addFont("Satisfy-Regular.ttf", "Satisfy", "normal");
            const ip =await fetch('https://api.ipify.org?format=json')
            .then((response) => response.json())
            .then((data) => data.ip)
            .catch((error) => console.error('Error:', error));
    
            const textContent = `
            I acknowledge that I am the account holder or legally authorized person to execute a contract on behalf of the account holder. I understand that by signing this agreement, I am switching the gas Supplier for this account to Cleansky Energy.I understand that the gas purchased for this account by Cleansky Energy will be delivered through ${miUser?.findUtility}'s delivery system.The account holder, or the person who signed this contract on behalf of the account holder, has 30 days after today to cancel this contract for any reason through written or verbal notification to Cleansky Energy.`;
                doc.setFont("helvetica", "normal"); 
                doc.text(`Contract ID: ${miUser?.id}`, 10, 20);
                doc.text(`Customer First Name: ${miUser?.first_name}`, 10, 30);
                doc.text(`Customer Last Name: ${miUser?.last_name}`, 10, 40);
                doc.text(`Customer Account Number: ${miUser?.ldc_account}`, 10, 50)
            
                const textLines = doc.splitTextToSize(textContent.trim(), 180); 
                doc.setFont("'Satisfy', cursive", "bold");
                doc.text(textLines, 10, 70);
    
                doc.setFont("helvetica", "normal");
                doc.text(`Signature: `, 10, 160);
                doc.setFont("Satisfy", "normal");
                doc.text(`${miUser?.signName} `, 38, 160);
                doc.setFont("helvetica", "normal");
                doc.text(`Date: ${new Date()?.toISOString()?.split('T')[0]}`, 10, 170);
                doc.text(`IP Address: ${ip}`, 10, 180);
            
                const pdfBase64 = btoa(doc.output());
            
                doc.save('Enrollment_Acknowledgement.pdf')
                // const pdfBlob = doc.output("blob");
                // const blobUrl = URL.createObjectURL(pdfBlob);
    
                // window.open(blobUrl, "_blank");
          };
    
    
    useEffect(()=>{
        (async()=>{
            let _isAdmin = await isAdmin();
            setIsAgent(_isAdmin)
            await isAdmin() && getScriptForAgent("7")
            let _res = await getEnrollmentRecord(enrollmentTokens?.enrollment_id, enrollmentTokens?.sign)
            shareAsaleNew2(enrollmentTokens?.enrollment_id, enrollmentTokens?.sign, "confirmation" , _res?.response?.service_state)
            updateDealHubspot(enrollmentTokens?.enrollment_id, dealStages.COMPLETED_ENROLLMENT_NO_AUTOPAY_DEPOSIT_NEEDED)
            setState(_res?.response?.service_state)
        })()
    },[])

    return (
        <EnrollmentResultContent>
            <div className="text-center">
                <h1 className="mb-3">{t('Thank you for choosing CleanSky Energy')}</h1>
                {isAgent?(state=="PA"?
                <p>
                    Your enrollment will become effective within the next 10 days or according to your utility’s scheduled timeframe for initiating service. Your confirmation number is E as in Echo | N as in November | L as in Lima | 6-digit date | 3-digit Extension|
                </p>:
                <p>
                    Your enrollment will become effective with the next available meter read date or according to your utility’s scheduled timeframe for initiating service. This typically can take up to two billing cycles, or 15-45 days as per the utility rules.  Your confirmation number is E as in Echo | N as in November | L as in Lima | 6-digit date | 3-digit Extension|
                </p>):<p>{t('Your enrollment request is now processing and may take up to 48 hours to completed. Your will receive an email when your status has changed.')}</p>}
                <p>{t('If you any questions please call')} <a href="tel:(888)733-5557">(888)733-5557</a> {t('or email')} <a href="mailto:customercare@cleanskyenergy.com">customercare@cleanskyenergy.com</a></p>
                <div style={{display:'flex',justifyContent:'center'}}>
                    {isMi && <div className="mt-5" style={{marginRight:"20px"}}>
                        <Button
                            onClick={() => {
                            generatePDF()
                            }}>
                            <VerticalAlignBottomOutlined style={{fontSize:"20px", marginRight:"10px",width:"25px"}} rev={undefined} />{t('Enrollment Acknowledgement')}
                        </Button>
                    </div>}
                    <div className="mt-5">
                    { isAgent?
                    <Button
                        withArrow
                        type='button'
                        onClick={() => {
                            window.location.href = adminPortalAgent
                        }}>
                        {t('Back to Enrollment Portal')}
                    </Button>:
                        <Button
                        withArrow
                        onClick={() => {
                            window.location.href = i18n.language=="en"?`https://cleanskyenergy.com/`:`https://cleanskyenergy.com/es`
                        }}>
                        {t('Back to Homepage')}
                    </Button>}
                </div> 
                </div>
                <div style={{border:'1px solid #ddd',borderRadius:12,padding:'36px 32px 12px',backgroundColor:'#fafafa'}} className="mt-5">
                    <p style={{fontSize:13}}>{t('Refer below to view, print, and/or download your contract and other important documents. Adobe Acrobat Reader must be installed on your computer. You may download Adobe Acrobat Reader free of charge from the Adobe web site')}: <a href="http://get.adobe.com/reader/" target="_blank" rel="noreferrer">{t('Download')} Adobe Reader</a></p>
                    {
                        Object.keys(allDocs).map((type: string, typeIdx: number) => {
                            return (
                                <div key={typeIdx} className="mb-4 mt-4 d-flex flex-column align-items-center">
                                    <h6>{t(`Documents for ${type} Plan`)}</h6>
                                    <div className="plan-links mt-3">
                                        {
                                            allDocs[type].map((doc: any, idx: number) => (
                                                <PlanSummaryRow narrow key={idx} className="text-start">
                                                    <a href={doc.url} target="_blank" key={idx} rel="noreferrer">
                                                        <FontAwesomeIcon icon="file-pdf" />
                                                        <span className="ms-2">{doc.display_name}</span>
                                                    </a>
                                                </PlanSummaryRow>
                                            ))
                                        }
                                    </div>
                                </div>
                            )
                        })
                    }
                </div>
            </div>
        </EnrollmentResultContent>
    )
}
