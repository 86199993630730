import { Trans, useTranslation } from "react-i18next";
import { EnrollmentResultContent } from "../../styles/Enrollment.styled";
import { errorMsg } from "../errorMsg";
import { Button } from "../../styles/Button.styled";
import { AgentFraudScreen } from "../AgentWebPortal/AgentFraudScreen";
import { adminPortalAgent } from "../../../config";
import { fetchUploadedDocuments } from "../../../api/api";
import { useContext, useEffect, useState } from "react";
import FraudDocDropZone from "../AgentWebPortal/DocUpload/FraudDocDropZone";
import { EnrollmentContext } from "../../../enrollment";

export const PendingVerification = ({ status, isAgent, next_step }: {
    status: string;
    isAgent: boolean;
    next_step: string;
}) => {
    const { t,i18n } = useTranslation('enrollment')
    const [uploadComplete, setUploadComplete] = useState<boolean>(false);
    const [loading, setLoading] = useState<boolean>(false);
    const { enrollmentTokens } = useContext<any>(EnrollmentContext);


    useEffect(()=>{
        (async()=>{
            try{
                await fetchDocs();    
            }catch(err){
                setUploadComplete(false);
            }
        })()
    },[])

    const fetchDocs = async() =>{
        setLoading(true)
        try{
            const res = await fetchUploadedDocuments(enrollmentTokens[0]?.enrollment_id, enrollmentTokens[0]?.sign);
            if(Number(res.response?.length) > 0){
                setUploadComplete(true)
            }
        }catch(err){
            setUploadComplete(false)
        }
        setLoading(false)
    }

    if (status === '30.E09') {
        return (
            <>
                {isAgent && <AgentFraudScreen />}
                {
                    true ? //KEEPING BOTH THE /verify PAGES COMMON either for DEPOSIT or for AUTOPAY to be the next screen
                    <EnrollmentResultContent>
                        <div className="text-center">
                            <h1 className="mb-3">{t('We are Almost There!')}</h1>
                            <b>{t('Your application for service with CleanSky Energy has been placed on hold, pending verification of your information. Your service will not start until such time as your information can be validated.')}</b> 
                            <p>
                                <div style={{textAlign: "left", fontSize: "16px", marginTop: "10px"}}>
                                    <Trans i18nKey="enrollment:fraudVerbiage2611_04"></Trans>
                                </div>
                                <div style={{textAlign: "left", marginLeft: "10px", marginTop: "10px", fontSize: "16px"}}>
                                    <ul>
                                        <li><Trans i18nKey="enrollment:fvdoc1">• A driver's license or state identification certificate issued by a state or territory of the United States.</Trans></li>
                                        <li><Trans i18nKey="enrollment:fvdoc2">• United States or foreign passport.</Trans></li>
                                        <li><Trans i18nKey="enrollment:fvdoc3">• U.S. military identification card.</Trans></li>
                                        <li><Trans i18nKey="enrollment:fvdoc4">• U.S. Department of Homeland Security identification document.</Trans></li>
                                        <li><Trans i18nKey="enrollment:fvdoc5">• U.S. Citizenship and Immigration Services identification document.</Trans></li>
                                        <li><Trans i18nKey="enrollment:fvdoc6">• U.S. Department of State identification document.</Trans></li>
                                    </ul>
                                </div>
                                <div style={{textAlign: "left", fontSize: "16px", marginTop: "10px"}}>
                                    <Trans i18nKey="enrollment:fraudVerbiage2611_01" components={{11: <a href="tel:877-424-1003" />, 12: <a href="mailto:Verify@CleanSkyEnergy.com" />}}></Trans>
                                </div>
                                <div style={{textAlign: "left", fontSize: "16px", marginTop: "10px", fontWeight: "bold"}}>
                                    <Trans i18nKey="enrollment:fraudVerbiage2611_02"></Trans>
                                </div>
                                <div style={{textAlign: "left", fontSize: "16px", marginTop: "10px"}}>
                                    <Trans i18nKey="enrollment:fraudVerbiage2611_03"></Trans>
                                </div>
                                <br/>
                            </p>
                            <div className="mt-5">
                                {isAgent?
                                    <Button
                                        withArrow
                                        type="button"
                                        onClick={() => {
                                            window.location.href = adminPortalAgent
                                        }}>
                                        {t('Back to Enrollment Portal')}
                                    </Button>:
                                    <Button
                                        withArrow
                                        onClick={() => {
                                            window.location.href = i18n.language=="en"?`https://cleanskyenergy.com/`:`https://cleanskyenergy.com/es`
                                        }}>
                                        {t('Back to Homepage')}
                                    </Button>}
                            </div>
                        </div>
                    </EnrollmentResultContent>:
                    (next_step !== "autopay" ?
                    <EnrollmentResultContent>
                        <div className="text-center">
                            <h1 className="mb-3">{t('We are Almost There!')}</h1>
                            {/* <p>
                                <b>{t('Your application for service with CleanSky Energy has been placed on hold, pending verification of your information.')}</b> 
                                <b>{t('Your service will not start until such time as your information can be validated.')}</b> 
                                <br/>
                                <br/>
                                <b>{t('Please send the following documents')}&nbsp;</b>
                                {t('Please send the following documents content')}
                                <br/>
                                <b>{t('Please send the following documents')}&nbsp;</b>
                                {t('Please send the following documents content')}
                                <br/>
                                <b>
                                <Trans i18nKey="enrollment:Pleaseemailthesedocuments" components={{11: <a href="tel:877-424-1003" />, 12: <a href="mailto:Verify@CleanSkyEnergy.com" />}}>
                                </Trans>
                                </b>
                                <br/>
                                <br/>
                                <Trans i18nKey="enrollment:ifyouhaveany" components={{11: <a href="tel:877-424-1003" />, 12: <a href="mailto:Verify@CleanSkyEnergy.com" />}}>
                                </Trans>
                                <br/>
                                <br/>
                            </p>
                            <p>
                                {t('fraudWarningDescAutopay2')}
                            </p>
                            <p>
                                {t('fraudWarningDescAutopay3')}
                            </p> */}
                            {/* Jay feedback 1608 */}
                                {/* <Trans i18nKey="enrollment:fraud1WarningDesc1806" components={{ 8: <a href="tel:(888) 733-5557" />, 9: <a href="mailto:verify@cleanskyenergy.com" />, p:<p/>}}>
                                </Trans>                            
                                <div style={{textAlign:'left'}}>
                                    <Trans i18nKey="enrollment:fraud2WarningDesc1806" components={{ 8: <a href="tel:(877) 424-1003" />, 9: <a href="mailto:customercare@cleanskyenergy.com" />,ol:<ol/>,ul:<ul style={{fontSize:"18px"}}/>,li:<li/>,p:<p style={{marginLeft:"20px"}}/>,br:<br/>}}>
                                    </Trans>
                                </div> */}
                            {/* document automation */}
                            <p>
                               
                                <div style={{textAlign: "left"}}>
                                    Your CleanSky Energy enrollment is currently on hold, pending verification.  Please upload one of the following forms of photo ID to complete your enrollment.
                                </div>
                                
                                <br/>
                                <div style={{textAlign: "left", marginLeft: "10px"}}>
                                    <ul>
                                        <li>• A driver's license or state identification certificate issued by a state or territory of the United States.</li>
                                        <li>• United States or foreign passport.</li>
                                        <li>• U.S. military identification card.</li>
                                        <li>• U.S. Department of Homeland Security identification document.</li>
                                        <li>• U.S. Citizenship and Immigration Services identification document.</li>
                                        <li>• U.S. Department of State identification document.</li>
                                    </ul>
                                </div>
                                <br/>
                                <div style={{textAlign: "left"}}>
                                    If you have these documents available, you may upload your documents below, otherwise, you will receive an email with instructions on how to upload your documents.
                                </div>
                                <br/>
                            </p>
                            <div style={{display:"flex",justifyContent:"center"}}>
                                <div className="dropzone-section">
                                    {
                                        loading ? <div style={{textAlign: "center"}} >Loading...</div> : (
                                            uploadComplete ? <div style={{color:"green", textAlign: "center"}} >Thank you, we will now use the documents provided to verify your enrollment.  Once verified, you will receive an email with instructions to complete your enrollment.</div> : <FraudDocDropZone fetchDocs={fetchDocs} docFor={"automation"}/>
                                        )
                                    }
                                </div>
                            </div>
                            <div className="mt-5">
                                {isAgent?
                                    <Button
                                        withArrow
                                        type="button"
                                        onClick={() => {
                                            window.location.href = adminPortalAgent
                                        }}>
                                        {t('Back to Enrollment Portal')}
                                    </Button>:
                                    <Button
                                        withArrow
                                        onClick={() => {
                                            window.location.href = i18n.language=="en"?`https://cleanskyenergy.com/`:`https://cleanskyenergy.com/es`
                                        }}>
                                        {t('Back to Homepage')}
                                    </Button>}
                            </div>
                        </div>
                    </EnrollmentResultContent> :
                    <EnrollmentResultContent>
                        <div className="text-center">
                            <h1 className="mb-3">{t('We are Almost There!')}</h1>
                            <p>
                                <b>{t('Your application for service with CleanSky Energy has been placed on hold, pending verification of your information.')}</b>&nbsp;
                                <b>{t('Your service will not start until such time as your information can be validated.')}</b> 
                                <br/>
                                <br/>
                                {/* <b>{t('Please send the following documents')}&nbsp;</b> */}
                                {/* {t('Please send the following documents content')} */}
                                <div style={{textAlign: "left"}}>
                                    {("Please send the following documents: One form of government issued ID (Driver's License (front & back), ID card (front & back). Below are examples of acceptable forms of identification:")}
                                </div>
                                
                                <br/>
                                <div style={{textAlign: "left", marginLeft: "10px"}}>
                                    <ul>
                                        <li>• A driver's license or state identification certificate issued by a state or territory of the United States.</li>
                                        <li>• United States or foreign passport.</li>
                                        <li>• U.S. military identification card.</li>
                                        <li>• U.S. Department of Homeland Security identification document.</li>
                                        <li>• U.S. Citizenship and Immigration Services identification document.</li>
                                        <li>• U.S. Department of State identification document.</li>
                                    </ul>
                                </div>
                                <br/>
                                <div style={{textAlign: "left"}}>
                                    {/* {("Please email these documents to verify@cleanskyenergy.com using the same email used to enroll. If you have any questions, please call our Customer Support Team at 877-424-1003 Mon – Fri: 7:00am-6:00pm and Sat 9am – 1pm (CST). If you do not have a Driver's License or government issued ID, please contact us; there may be other options available.")} */}
                                    <Trans i18nKey="enrollment:Pleaseemailthesedocuments" components={{11: <a href="tel:877-424-1003" />, 12: <a href="mailto:Verify@CleanSkyEnergy.com" />}}></Trans>
                                    <Trans i18nKey="enrollment:ifyouhaveany" components={{11: <a href="tel:877-424-1003" />, 12: <a href="mailto:Verify@CleanSkyEnergy.com" />}}></Trans>
                                    </div>
                                <br/>
                                <p style={{textAlign:"left", fontWeight: "bold"}}>
                                    <Trans i18nKey="enrollment:youllreceive">
                                    </Trans>
                                </p>
                                {/* <br/>
                                <br/>
                                <Trans i18nKey="enrollment:ifyouhaveany" components={{11: <a href="tel:877-424-1003" />, 12: <a href="mailto:Verify@CleanSkyEnergy.com" />}}>
                                </Trans>
                                <br/>
                                <br/> */}
                            </p>
                            {/* <p>
                                <b>{t('fraudWarningDescDeposit4')}</b> 
                                {t('fraudWarningDescDeposit2')}
                            </p> */}
                            <p style={{textAlign: "left"}}>
                                {t('Please note that you must contact us within the next 30 days to authenticate your information otherwise your application with CleanSky Energy will be cancelled.')}
                                {t('Once your application is cancelled, we will not honor the rate provided and you will have to reapply as a new customer.')}
                            </p>
                            <div className="mt-5">
                                {isAgent?
                                    <Button
                                        withArrow
                                        type="button"
                                        onClick={() => {
                                            window.location.href = adminPortalAgent
                                        }}>
                                        {t('Back to Enrollment Portal')}
                                    </Button>:
                                    <Button
                                        withArrow
                                        onClick={() => {
                                            window.location.href = i18n.language=="en"?`https://cleanskyenergy.com/`:`https://cleanskyenergy.com/es`
                                        }}>
                                        {t('Back to Homepage')}
                                    </Button>}
                            </div>
                        </div>
                    </EnrollmentResultContent>)
                } <style>
                {`
                    .dropzone-section {
                        margin-top: 40px;
                        // max-width: 544px;
                        width: 100%;
                        padding: 30px;
                        background-color: #EFF7FA;
                        border-radius: 12px;
                    }

                    @media (max-width: 768px) {
                        .main-div {
                            padding: 0 20px;
                        }
                        .upload-files-label {
                            font-size: 20px; /* smaller font size for tablet */
                        }
                        .upload-files-sublabel {
                            width: 100%;
                        }
                        .dropzone-section{
                            width: 100%;
                            display: flex;
                            justify-content: center;
                        }
                    }

                    @media (max-width: 480px) {
                        .upload-files-label {
                            font-size: 20px; /* even smaller font size for mobile */
                        }
                    }
                `}
            </style>
            </>
        )
    }

    return (
        <EnrollmentResultContent>
            <div className="text-center">
                <h1 className="mb-3">{errorMsg[status].headline}</h1>
                <p>{errorMsg[status].paragraph}</p>
                {
                    errorMsg[status].paragraph2 &&
                    <p>{errorMsg[status].paragraph2}</p>
                }
                {
                    (errorMsg[status].buttonType && errorMsg[status].buttonType === 'myaccount') ?
                    <div className="mt-5">
                        <Button
                            withArrow
                            onClick={() => {
                                window.location.href = i18n.language=="en"?`https://cleanskyenergy.com/`:`https://cleanskyenergy.com/es`
                            }}>
                            Back to Homepage
                        </Button>
                    </div> :
                    <div className="mt-5">
                        <Button
                            onClick={() => {
                                window.location.href = i18n.language=="en"?`https://cleanskyenergy.com/`:`https://cleanskyenergy.com/es`
                            }}>
                            Back to Homepage
                        </Button>
                    </div>
                }
            </div>
        </EnrollmentResultContent>
    )
}
