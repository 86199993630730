import React, { useContext, useState } from "react";
import { Upload, message } from "antd";
import { UploadOutlined, FileOutlined, CloseCircleFilled } from "@ant-design/icons";
import { Button } from '../../../styles/Button.styled';
import "./dropzone.css";
import { fraudDocumentUploader } from "../../../../api/api";
import { EnrollmentContext } from "../../../../enrollment";
 
const { Dragger } = Upload;

const props = {
    name: 'file',
    multiple: true,
    action: 'https://run.mocky.io/v3/435e224c-44fb-4773-9faf-380c5e6a2188',
    onChange(info) {
      const { status } = info.file;
      if (status !== 'uploading') {
        console.log(info.file, info.fileList);
      }
      if (status === 'done') {
        message.success(`${info.file.name} file uploaded successfully.`);
      } else if (status === 'error') {
        message.error(`${info.file.name} file upload failed.`);
      }
   
    },
    onDrop(e) {
      return e.dataTransfer.files;
    },
 
 
 
   
    // Add the itemRender prop here
    itemRender(originNode, file, fileList, actions) {
 
      return (
          <>
          <div className="upload-bar">
            <div className="sub-upload-bar">
                <FileOutlined />
                <span className="file-name">{file.name}</span>
            </div>
            <CloseCircleFilled className="close-icon" style={{fontSize:'20px', marginLeft:'8%'}} onClick={() => actions.remove(file)} />
          </div>
 
          </>
      );
    },
 
  };
function FraudDocDropZone({fetchDocs, docFor}) {
  // Create a state variable for the fileList and initialize it as an empty array
  const [fileList, setFileList] = useState([]);
  const { enrollmentTokens } = useContext(EnrollmentContext) || {};
  const [cancel,setCancel]=useState(false)
  const [loading,setLoading] = useState(false)

  console.log('Data in key ==>', docFor)

  // Update the fileList state variable when the files change
  const handleChange = (info) => {
    setFileList(info.fileList);
  };
 
  // function to handle the file submission
  const handleSubmit = async() => {
    // Add a conditional check before calling the forEach method
    // You can use the optional chaining operator (?.) or the logical AND operator (&&)
    try{
        setLoading(true)
        const url = new URL(window.location.href);
        const idParam = url.searchParams.get('id');
        const signParam = url.searchParams.get('sign');
 
        const res = await fraudDocumentUploader(fileList, idParam || enrollmentTokens[0]?.enrollment_id, signParam || enrollmentTokens[0]?.sign, docFor) //automation is used for fraud docs
        if(res?.status == 200){
            setFileList([]);
            await fetchDocs();
            message.success({
                content: 'documents uploaded successfully',
                style: {
                    position: 'absolute',
                    width: "100%",
                    top: "30px"
                },
            });
        }
        setLoading(false)
    }catch(err){
        setLoading(false)
        console.log('Data in err ==>', err);
        message.error({
            content: 'failed to upload documents. Please try again.',
            style: {
                position: 'absolute',
                width: "100%",
                top: "30px"
            },
        });
    }

  };
 
  return (
    <div className="file-uploader">
      {cancel?
      <div style={{textAlign:"left"}}>
        Please note that your enrollment is not complete until you verify your enrollment information.  Once verified you will receive an email with instructions to complete your enrollment.<br/>
        Your application will be canceled within 30 days if the information has not been provided to us. Rates will during the time of the enrollment will expire and will not be honored.
      </div>
      :<Dragger
      {...props}
        name="file"
        multiple={true}
        onChange={handleChange}
        style={{padding:'20px', background:'white'}}
        fileList={fileList}
      >
            <p className="ant-upload-drag-icon">
            <UploadOutlined />
            </p>
            <p className="ant-upload-text" style={{color:'#254066'}}>Choose a file or drag & drop it here</p>
            <p className="ant-upload-hint">
            JPEG, PNG, PDG, and MP4 formats, up to 50MB
            </p>
      </Dragger>}
      {!cancel &&<div style={{display:"flex",justifyContent:"center"}}>
        <Button className="send-button2" onClick={()=>setCancel(true)} style={{marginRight:"16px"}}>
          Cancel
        </Button>
        <Button className="send-button2" onClick={handleSubmit} disabled={loading}>
          Upload
        </Button>
      </div>}
    </div>
  );
}
 
export default FraudDocDropZone;
