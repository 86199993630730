import { useEffect, useState } from "react";
import DropZon from "./DropZon";
import { fetchUploadedDocuments } from "../../../../api/api";
import FraudDocDropZone from "./FraudDocDropZone";

const FraudDocUploadScreen = () => {

    const [uploadComplete, setUploadComplete] = useState<boolean>(false);
    const [loading, setLoading] = useState<boolean>(false);

    useEffect(()=>{
        (async()=>{
            try{
                await fetchDocs();    
            }catch(err){
                setUploadComplete(false);
            }
        })()
    },[])

    const fetchDocs = async() =>{
        setLoading(true)
        try{
            const url = new URL(window.location.href);
            const idParam = url.searchParams.get('id');
            const signParam = url.searchParams.get('sign');

            const res = await fetchUploadedDocuments(idParam, signParam);
            if(Number(res.response?.length) > 0){
                setUploadComplete(true)
            }
        }catch(err){
            setUploadComplete(false)
        }
        setLoading(false)
    }

    return(
        <>
            <div className="main-div">
                <span className="upload-files-label">UPLOAD FILES</span>
                <span className="upload-files-sublabel">
                Please upload the front and back of one of the following forms of photo ID to complete your enrollment.  
                    <br/>•   A driver's license or state identification certificate issued by a state or territory of the United States.
                    <br/>•   United States or foreign passport.
                    <br/>•   U.S. military identification card.
                    <br/>•   U.S. Department of Homeland Security identification document.
                    <br/>•   U.S. Citizenship and Immigration Services identification document.
                    <br/>•   U.S. Department of State identification document.
                </span>
                <div className="dropzone-section">
                    {
                        loading ? <div style={{textAlign: "center"}} >Loading...</div> : (
                            uploadComplete ?
                            <>
                                <div style={{color:"green", textAlign: "center"}} >Thank you, we will now use the documents provided to verify your enrollment.  Once verified, you will receive an email with instructions to complete your enrollment.</div><br/>
                                <div style={{color:"green",textAlign:"center"}}>You may now close this page</div>
                            </>
                             : <FraudDocDropZone fetchDocs={fetchDocs} docFor={"automation"}/>
                        )
                    }
                </div>
            </div>
            <style>
                {`
                    .main-div {
                        display: flex;
                        flex-direction: column;
                        align-items: center;
                    }
                    .upload-files-label {
                        font-size: 24px;
                        font-weight: 700;
                        line-height: 32.68px;
                    }
                    .upload-files-sublabel {
                        font-size: 14px;
                        font-weight: 400;
                        line-height: 19px;
                        text-align: left;
                        margin-top: 26px;
                        max-width: 544px;
                        width: 40%;
                    }
                    .dropzone-section {
                        margin-top: 40px;
                        max-width: 544px;
                        width: 40%;
                        padding: 30px;
                        background-color: #EFF7FA;
                        border-radius: 12px;
                    }

                    @media (max-width: 768px) {
                        .main-div {
                            padding: 0 20px;
                        }
                        .upload-files-label {
                            font-size: 20px; /* smaller font size for tablet */
                        }
                        .upload-files-sublabel {
                            width: 100%;
                        }
                        .dropzone-section{
                            width: 100%;
                        }
                    }

                    @media (max-width: 480px) {
                        .upload-files-label {
                            font-size: 20px; /* even smaller font size for mobile */
                        }
                    }
                `}
            </style>
        </>
    )
}

export default FraudDocUploadScreen;
